import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { AprovarAcordoAnexosComponent } from '../aprovar-acordo-anexos/aprovar-acordo-anexos.component';

@Component({
    selector: 'app-modal-aprovar-acordo-judicial',
    templateUrl: './modal-aprovar-acordo-judicial.component.html',
    styleUrls: ['./modal-aprovar-acordo-judicial.component.scss']
})
export class ModalAprovarAcordoJudicialComponent implements OnInit {

    private modalRef: NgbModalRef;
    public acordoId: any;
    public loadingAcordo: boolean=true;
    public inEdit: boolean=false;
    resultConfirmDialog$ = new Subject<ResultadoModal>();


    public acordo: any[];
    public tipos: any[];

    constructor(
        public acordoService: AcordoJuridicoService,
        public toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        private ngbModal: NgbModal,
    ) { }

    ngOnInit(): void {
        debugger;
        this.carregarAcordo(this.acordoId);
        this.carregarTiposDividas();
    }
    carregarTiposDividas(){
      this.tipos = [];
     /* this.tipos = [
        {
          "id" : 1,
          "nome" : "em dia"
        },
        {
          "id" : 2,
          "nome" : "atrasado"
        }
      ];
      */
      this.acordoService.obterSituacaoAcordoDivida().subscribe(
        res => {
          this.tipos = res.data;
        }
      )
    }
    atualizarSituacaoDivida(acordo){
      var data = {
        "id" : acordo.id ,
        "nome" : acordo.situacaoAcordoJudicialDivida
      };
      //console.log("data: " , data)
      this.acordoService.atualizarSituacaoAcordoDivida(data);
    }

    async carregarAcordo(acordos) {
        console.log(acordos);
        this.loadingAcordo = true;
        this.inEdit = false;
        this.acordo = [];
        acordos.forEach(async (item,index) => {
           await this.carregarAcordoItem(item, index);
        });
        
    }
    async carregarAcordoItem(id,index){
      this.acordoService.obterPorId(id).subscribe(res=>{
        
        if(res.data.dividas.length)
        {
            this.acordo.push(res.data);
            this.acordo[index].dividas.forEach((element:any) => {
                element.valorAbertoTemp = element.valorAberto;
                element.alterado = false;
            });        
        }

        this.loadingAcordo = false;
        this.spinner.hide();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Erro ao gravar comentário!', 'Atenção', { timeOut: 10000 });
            }
        })
    }


    calcularSimulacao(parcela, acordo){

        if(parcela.valorAbertoTemp == parcela.valorAberto) return;

        parcela.valorAbertoTemp = parcela.valorAberto;

        this.inEdit = true;

        parcela.alterado = true;

        var totalParcelas = acordo.dividas.length;
        var totalParcelasRestantes = totalParcelas;

        var valorTotalCalculo = 0;

        var valorSobraParcela = 0;

        var valorTotalAcordo = 0;

        acordo.dividas.forEach(valor => {
            if (valor.alterado) {
                valorTotalCalculo += valor.valorAberto;
                totalParcelasRestantes--;
            }
        });

        acordo.dividas.forEach(valor => {

            if (!valor.alterado) {
                var valorCalculo = (acordo.valorAberto - valorTotalCalculo) / (totalParcelasRestantes);
                valor.valorAberto = Math.floor(valorCalculo * 100) / 100;


                if (valor.parcela == totalParcelas) {
                    valor.valorAberto = acordo.valorTotal - valorSobraParcela;
                }
            }

            valorSobraParcela += valor.valorAberto;

            valorTotalAcordo += valor.valorAberto;
        });

        acordo.valorTotal = valorTotalAcordo;
    }

    salvar() {
        this.acordo.forEach(item => {
          this.salvarItem (item);
        });
        this.carregarAcordo(this.acordoId);
    }
    salvarItem (item){
      this.spinner.show();

      this.acordoService.atualizarSimulacao(item).subscribe(res=>{

      }, error => {
          this.spinner.hide();
          if (error && error.errors) {
              this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
          }
          else {
              this.toastrService.error('Erro ao gravar!', 'Atenção', { timeOut: 10000 });
          }
      }
      );
    }
    aprovar(){
    
    this.spinner.show();
    
    const referenciaId = this.acordo[0].referenciaId;

    const modalRef = this.ngbModal.open(AprovarAcordoAnexosComponent
        , {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.referenciaId = referenciaId;
        modalRef.componentInstance.page = 'AprovarAcordoAnexo';
        this.spinner.hide();
        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            this.modalRef.close();
        }).catch(error => { });    
    }

    aprovarItem(item){
      this.spinner.show();

      this.acordoService.aprovarAcordo(item).subscribe(res=>{
          this.modalRef.close();
      }, error => {
          this.spinner.hide();
          if (error && error.errors) {
              this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
          }
          else {
              this.toastrService.error('Erro ao gravar!', 'Atenção', { timeOut: 10000 });
          }
      });
    }

    recarregar(){
        this.carregarAcordo(this.acordoId);
    }

    dismiss() {
        this.modalRef.dismiss();
    }
}
